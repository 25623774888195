import * as ajax from '@integrabeauty/shopify-ajax-api';
import { applyDiscountsHelper } from '../../lib/cart-apply-discounts-helper.js';
import { getDiscountCodes, isWellFormed } from '../../lib/cart-discount.js';
import * as CartState from '../../lib/cart-state.js';
import { Transaction } from '../../lib/cart-transaction.js';
import { copy } from '../../lib/object.js';
import { retry } from '../../lib/promise-retry.js';

export type AttributeMixin = ()=> ajax.Attributes;

/**
 * Initialize, or re-initialize, cart state.
 *
 * On success, emits a cart updated event. The event has an is_initial property set to true to
 * distinguish initial cart updated events from other kinds of cart updated events. This is helpful
 * because the diffs for initial cart updated events are not populated.
 *
 * On error, a cart update erred event is emitted instead of a cart updated event.
 *
 * The initial cart updated event may occur multiple times on a page because a cart can be
 * initialized multiple times. Listeners should not assume a cart is only initialized once per page.
 *
 * Any cart updated event can reference a new cart with a new id. Users should not assume the id is
 * constant.
 *
 * This does not trace errors. Callers are responsible for error tracing.
 *
 * This can throw, but generally does not. Instead, when there is a problem, an event is emitted. As
 * such, callers need to handle errors both by checking for whether the function threw and also by
 * observing error events. The promise rejection must be handled because we have a rule that all
 * calls to promise returning functions (which means all async qualified functions) must catch
 * promise rejections because of the frequent mistake of accidentally ignoring promise rejections,
 * in other words, ignoring errors. However, this does not throw unless something is really wrong
 * such as lack of support for JavaScript class syntax.
 *
 * This does not check whether there is existing cart state that is stale. This will always fetch
 * the latest state.
 */
export async function cartInit(queue: Event[], immutableAttributeKeys: string[],
  mixins: AttributeMixin[]) {
  let success = false;

  const transaction = new Transaction('init');
  try {
    await initUnsafe(transaction, immutableAttributeKeys, mixins);
    success = true;
  } catch (error) {
    type Detail = WindowEventMap['cart-update-erred']['detail'];
    const event = new CustomEvent<Detail>('cart-update-erred', {
      detail: {
        code: 'INIT',
        error,
        inputs: {
          country: Shopify.country
        },
        transaction
      }
    });
    dispatchEvent(event);
  } finally {
    transaction.complete();
  }

  // Listen for changes in other tabs. This waits to listen until after the init transaction has
  // completed because there is no point to observing before this point and because observing can
  // immediately lead to new transactions starting, which must be avoided because there should only
  // ever be a single active transaction, because concurrent updates are not yet supported.
  //
  // The message listener logic will always short circuit if there has not been a successful init so
  // observing too early is just wasted cycles.
  //
  // The try/catch here is unnecessary however cartInit must not fail so the extra paranoia is ok.
  //
  // We only start observing if the init transaction appears to have completed successfully. We do
  // not want to observe changes in other tabs when init has failed.

  if (success) {
    try {
      CartState.observe(queue);
    } catch (error) {
      console.warn(error);
    }
  }
}

/**
 * We have to fetch cart state instead of reading in cart state from ssr because there is no way to
 * render cart.discount_codes. There is no longer a benefit to server side rendering of initial cart
 * state because of this mandatory fetch.
 *
 * @todo request Shopify support cart.discount_codes in liquid
 */
async function initUnsafe(transaction: Transaction, immutableAttributeKeys: string[],
  attributeMixins: AttributeMixin[]) {
  let cart = await retry(ajax.cartFetch(), 2);
  cart = await enrichAttributes(cart, immutableAttributeKeys, attributeMixins);
  cart = await applyUrlDiscounts(cart);
  CartState.write(cart, transaction, 'init', true);
}

function getDiscountCodeFromUrl() {
  const url = new URL(location.href);
  return url.searchParams.get('couponcode')?.trim().toUpperCase();
}

/**
 * Attempt to apply discount codes parsed from the coupon code url parameter on cart init. This is
 * a best effect operation that suppresses errors.
 *
 * Previously we started with a length check that exited early when there are no items in cart,
 * because it is not possible to apply any discount to any empty cart. However, this is not entirely
 * accurate. It is possible to apply a discount code to an empty cart. The discount will be applied
 * but in in the inapplicable state. Later, when someone adds to cart, and the requirements for the
 * discount are met, the discount becomes applicable.
 */
async function applyUrlDiscounts(cart: ajax.Cart) {
  const param = getDiscountCodeFromUrl();
  if (!param) {
    return cart;
  }

  const codesToApply = param.split(',');

  // Automatic discount application from a url parameter is a possibly frequent operation. We want
  // to minimize the number of cart updates, so it is worth checking if there is an opportunity to
  // noop. When all of the new codes to apply are already applied, do nothing.

  // Get the subset of codes parsed from the url parameter that appear to be valid
  const validCodes = codesToApply.filter(isWellFormed);

  // Normalize the codes for simple accurate comparison to current codes
  const normalCodes = validCodes.map(code => code.toUpperCase());

  // Deduplicate
  const uniques = [...new Set(normalCodes)];

  // Get the codes that are already applied. This includes codes that are applied but in the
  // inapplicable state because we want to retain those in the update. The getDiscountCodes function
  // guarantees the codes are uppercase.

  const existingCodes = getDiscountCodes(cart);

  // Get the subset of new codes that are not already applied.

  const deltas = uniques.filter(code => !existingCodes.includes(code.toUpperCase()));

  // If there are no new codes in the subset, return the cart as is.

  // TODO: this no-op check should happen in applyDiscountsHelper, not here

  if (deltas.length === 0) {
    return cart;
  }

  // Apply the new codes. In doing so, disable post validation because ordinarily there is always an
  // error for an empty cart and because these are automatically applied codes. Treat failure to
  // apply the new codes as non-fatal.

  let outputCart = cart;
  try {
    outputCart = await applyDiscountsHelper(cart, deltas, cart_init.discount_overrides, false);
  } catch (error) {
    console.warn(error);
  }

  return outputCart;
}

/**
 * Try to update the cart's attributes and then return the updated cart state. Errors are
 * suppressed. On error the original cart state is returned.
 *
 * Because of the lack of transaction safety, in that our only option is to issue multiple mutating
 * api calls with no safe rollback mechanism, it is possible to for this to update a cart's
 * attributes but still return the old state because the call to fetch the new state failed. In this
 * case, the server state is up to date but the client state is not. This is not the worst thing
 * when there are no future updates to cart attribute state for the remainder of the page load.
 *
 * The Shopify cart api update call works as follows. Attributes that exist in cart attributes that
 * are not specified in the update call are retained. Attributes that exist in cart attributes that
 * are specified are overwritten. If the new value is null, then the attribute is removed. To be
 * clear, there is no need to always send all attributes with every update.
 *
 * This only issues a request to update attributes when there are new attribute values.
 */
async function enrichAttributes(cart: ajax.Cart, immutableKeys: string[],
  mixins: AttributeMixin[]) {
  let output = cart;
  const oldAttributes = fixAttributes(cart.attributes);
  const newAttributes = mixinAttributeMixins(mixins);

  const attributes: ajax.Attributes = {};
  for (const key in newAttributes) {
    if ((immutableKeys.includes(key) && key in oldAttributes) ||
      compareAttributeValues(oldAttributes[key], newAttributes[key])) {
      // skip
    } else {
      attributes[key] = newAttributes[key];
    }
  }

  if (Object.keys(attributes).length > 0) {
    try {
      await ajax.cartUpdate({ attributes });
      output = await retry(ajax.cartFetch(), 3);
    } catch (error) {
      console.warn(error);
    }
  }

  return output;
}

/**
 * Returns whether an attribute value is permitted.
 *
 * We want to explicitly reject certain values such as XSS attempts. While XSS is mitigated more
 * strictly in other ways, we do not even want such values making their way into the page or
 * downstream state.
 */
function isAllowedAttributeValue(value?: any) {
  if (typeof value === 'string' && /javascript:/i.test(value)) {
    return false;
  }

  return true;
}

/**
 * Returns whether two attribute values are equivalent. This is not strict equivalence. For values
 * that are objects, we only check the number of properties.
 *
 * Currently this does not treat two nulls as equal.
 */
function compareAttributeValues(value0: ajax.AttributeValue, value1: ajax.AttributeValue) {
  if (typeof value0 === 'string' && typeof value1 === 'string') {
    return value0 === value1;
  }

  if (typeof value0 === 'object' && value0 !== null && typeof value1 === 'object' &&
    value1 !== null) {
    // for nested objects we just do a shallow check
    const keys0 = Object.keys(value0);
    const keys1 = Object.keys(value1);
    return keys0.length === keys1.length;
  }

  return false;
}

/**
 * Runs each attribute generating mixin function. If the function does not error and produces an
 * attributes object, then this visits each key value pair. If the value of a pair is allowed, then
 * it is merged into an aggregate attributes object. The aggregated attributes object is returned.
 *
 * This performs a try/catch per mixin so that each mixin is isolated from the rest. This way if one
 * mixin is buggy it does not impact the others. This also frees up the mixins to not be concerned
 * with error handling.
 */
function mixinAttributeMixins(mixins: AttributeMixin[]) {
  const attributes: ajax.Attributes = {};

  for (const mixin of mixins) {
    try {
      const generatedAttributes = mixin();

      if (generatedAttributes) {
        for (const key in generatedAttributes) {
          const value = generatedAttributes[key];
          if (isAllowedAttributeValue(value)) {
            attributes[key] = value;
          }
        }
      }
    } catch (error) {
      console.warn(error);
    }
  }

  return attributes;
}

/**
 * Rewrites the values of attributes to fix issues with attributes that are incorrectly rendered
 * via liquid.
 *
 * This returns a copy of the input that has the changes. The input is never changed.
 *
 * We render cart state into liquid in a couple of places:
 *
 * * on page load, accessed on init
 * * on cart update, when fetching a custom cart
 *
 * When rendering attributes in liquid, we use the json liquid filter. It turns out that the liquid
 * filter exhibits several undesirable and surprising behaviors:
 *
 * * it html encodes certain characters such as ampersands, quotes, and less/greater than
 * * it unicode encodes certain characters such as ampersands
 * * it escapes forward slashes
 *
 * This function is meant to run on the serialized value generated by liquid as properties of a
 * JSON object that has been parsed back into an actual JavaScript object. This corrects several of
 * the values.
 *
 * We are doing this in JavaScript instead of in liquid because accurately expressing the logic in
 * liquid is abnormally difficult.
 *
 * This function is written in a defensive so as to never error. It should be safe to call without
 * a try/catch on invalid input.
 *
 * This function does prevent the ability to store an html encoded value in cart attributes. There
 * is unfortunately no way to distinguish between an intentional storing of such a value and an
 * unintentional storing of such a value.
 */
function fixAttributes(attributes: ajax.Attributes) {
  if (typeof attributes !== 'object' || attributes === null) {
    return null;
  }

  // Clone the input attributes object. We clone because are going to mutate the output before
  // returning it and want to shield ourselves from surprise behavior, e.g. callers who are still
  // holding a live reference to the input object.

  const output = copy(attributes);

  for (const key in output) {
    let value = output[key];

    // We have to be careful here of the value we are dealing with. The value might be undefined,
    // null, or an object (cart attributes can be objects, surprisingly). We only want to mess with
    // strings.

    if (typeof value === 'string') {
      // The regular expressions assume that the encoding that Shopify's liquid filter performs
      // always produce lowercase values and that cart attribute string values never contain line
      // breaks (so no need for multiline flag).

      // Undo escaping of forward slashes (change \/ to /)

      value = value.replace(/\\\//g, '/');

      // Undo unicode encoding of ampersands. This try/catch is paranoia around using unicode in
      // regex, some browsers are weird.

      try {
        value = value.replace(/\u0026/g, '&');
      } catch (error) {
        // ignore
      }

      // Decode certain html entities that we have observed the json liquid filter encoding.

      value = value.replace(/&amp;/g, '&');
      value = value.replace(/&quot;/g, '"');
      value = value.replace(/&gt;/g, '>');
      value = value.replace(/&lt;/g, '<');

      // Fix issues with corrupted values resulting from repeat encoding. What happens is that the
      // string contains an "&". That gets encoded as "&amp;". Then, after another liquid render
      // that is not careful, that gets encoded as "&amp;amp;". Then it repeats. We already decoded
      // the first &amp; above. That leaves us with a bunch of "amp;" garbage to excise.
      value = value.replace(/amp;/g, '');

      output[key] = value;
    }
  }

  return output;
}
